<template>
   <div>
      <el-form label-width="80px">
         <h2>{{ wenjuan.name }}</h2>
         <el-form-item v-for="(v, k) in wenjuan.ques" :key="k">
            <h4>{{ k + 1 }}，{{ v.title }}（单选题）</h4>
            <p v-for="(x, y) in v.option" :key="y" :label="x.id">
               {{ x.title }}
               <el-row>
                  <el-col :span="12">
                     <el-progress
                        :percentage="x.ratio * 100"
                        :color="customColor"
                        :text-inside="true"
                        :stroke-width="18"
                     ></el-progress>
                  </el-col>
               </el-row>
            </p>
         </el-form-item>
      </el-form>
   </div>
</template>

<script>
import { Form, FormItem, Progress, Row, Col } from "element-ui";
import { http } from "@/http";

export default {
   components: {
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Progress.name]: Progress,
      [Row.name]: Row,
      [Col.name]: Col,
   },
   data() {
      return {
         customColor: "#d1a861",
         wenjuan: [],
      };
   },
   mounted() {
      this.getQues();
   },
   methods: {
      getQues() {
         http
            .get(
               `wenjuan/result/${this.$route.params.wenjuan_id}`,
               {},
               { loading: true },
            )
            .then((res) => {
               this.wenjuan = res.data;
            });
      },
   },
};
</script>

<style lang="scss" scoped>
h2 {
   text-align: center;
}
</style>
