<template>
   <div class="room">
      <div class="room-plate">
         <div class="room-plate__inner">真假烟鉴别方法</div>
      </div>

      <div class="room-content">
         <h3>一查</h3>
         <ul>
            <li><p>1、查看卷烟透明薄膜是否有二次粘封、人为刀割的痕迹。</p></li>
            <li><p>2、查看小盒背面是否有翻盖人为打开过的折痕。</p></li>
         </ul>
         <h3>二比</h3>
         <ul>
            <li><p>1、比较对照商标图案</p></li>
            <li>
               <p>2、比较对照防伪图案</p>
               <ul>
                  <li>
                     <p>
                        <strong>真烟：</strong>
                        防伪图像与文字能完全分离
                     </p>
                  </li>
                  <li>
                     <p>
                        <strong>假烟：</strong>
                        防伪图案与文字叠加
                     </p>
                  </li>
               </ul>
            </li>
            <li>
               <p>3、比较对照防伪喷码</p>
               <ul>
                  <li>
                     <p>
                        <strong>真烟：</strong>
                        小盒喷码在透明薄膜里层，擦不掉
                     </p>
                  </li>
                  <li>
                     <p>
                        <strong>假烟：</strong>
                        小盒喷码在透明薄膜表层，可以擦掉
                     </p>
                  </li>
               </ul>
            </li>
            <li><p>4、比照产品追溯标识</p></li>
         </ul>
         <h3>三拆</h3>
         <ul>
            <li>
               <p>1、拆开烟盒小盒包装查看，看包装胶点形状，看包装胶点数量</p>
            </li>
            <li>
               <p>2、拆开烟支查看烟丝质量</p>
               <ul>
                  <li>
                     <p>
                        <strong>真烟：</strong>
                        真烟烟丝颜色橙黄、正黄，光泽油润；烟丝卷曲、蓬松，富有弹性，烟丝宽度
                        均匀。真烟烟丝中掺有梗丝，梗丝膨化效果好，呈很薄片状 。
                     </p>
                  </li>
                  <li>
                     <p>
                        <strong>假烟：</strong>
                        假烟烟丝颜色较深、偏黑，光泽暗淡，油润性差，弹性差，切丝宽度、长度不均匀。假烟烟丝中不含梗丝或所含梗丝膨化效果不好，梗丝较厚，呈粒状、块状。
                     </p>
                  </li>
               </ul>
            </li>
         </ul>
         <h3>四闻</h3>
         <ul>
            <li>
               <p>
                  <strong>1、真烟：</strong>
                  烟草本香气味浓郁，烟香与香精香气谐调、柔和、舒适。
               </p>
            </li>
            <li>
               <p>
                  <strong>2、假烟：</strong>
                  假烟：香精味重，烟香本香平淡不足、香气不协调或是未经加香加料处理，青杂气非常。
               </p>
            </li>
         </ul>
         <h3>五品</h3>
         <ul>
            <li>
               <p>
                  <strong>1、真烟：</strong>
                  抽吸时香气优雅、丰满、谐调，无杂气，无刺激，余味纯净。
               </p>
            </li>
            <li>
               <p>
                  <strong>2、假烟：</strong>
                  假烟抽吸时香味较淡，杂气、刺激性大，甚至有的还有异味或霉味。
               </p>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
export default {
   name: "PageTobaccoClassroom",
};
</script>

<style lang="scss" scoped>
.room {
   &-plate {
      &__inner {
         position: relative;
         display: inline-block;
         padding: 5px 8px;
         font-size: 20px;
         color: #fff;
         font-weight: 700;
         line-height: 1;
         vertical-align: top;
         background: var(--color-main);

         &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 11px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-top-color: var(--color-main);
         }
      }
   }

   &-content {
      h3 {
         margin: 1.5em 0 1em;
         font-weight: bold;
         font-size: 18px;
         color: var(--color-main);
      }

      p {
         margin: 1em 0;
         font-size: 14px;
      }

      strong {
         color: var(--color-main);
      }

      ul {
         padding: 0;
         margin-left: 1.6em;
         font-size: 14px;
         list-style: none;

         li {
            margin: 1em 0;
         }
      }
   }
}
</style>
