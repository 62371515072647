<template>
   <div>
      <!-- banner -->
      <com-head-banner v-if="banner.length > 0" :src="banner"></com-head-banner>

      <com-page-main>
         <div class="body">
            <div class="body-title">{{ title }}</div>
            <div class="body-box">
               <div class="body-menu">
                  <div
                     v-for="item in menu"
                     :key="item.path"
                     class="body-menu__item"
                     :class="{ active: $route.path === item.path }"
                  >
                     <template v-if="'children' in item">
                        <a
                           class="body-menu__link"
                           :class="{
                              active: $route.path.indexOf(item.path) === 0,
                           }"
                        >
                           {{ item.label }}
                        </a>
                        <div class="body-menu__sub">
                           <div
                              v-for="todo in item.children"
                              :key="todo.path"
                              class="body-menu__sub__item"
                           >
                              <router-link
                                 class="body-menu__sub__link"
                                 :to="todo.path"
                              >
                                 {{ todo.label }}
                              </router-link>
                           </div>
                        </div>
                     </template>

                     <router-link
                        v-else
                        class="body-menu__link"
                        :to="item.path"
                     >
                        {{ item.label }}
                     </router-link>
                  </div>
               </div>
               <div class="body-content">
                  <router-view></router-view>
               </div>
            </div>
         </div>
      </com-page-main>
   </div>
</template>

<script>
import ComHeadBanner from "@/components/HeadBanner";
import ComPageMain from "@/components/PageMain";

export default {
   name: "ComMenuBody",
   components: {
      ComPageMain,
      ComHeadBanner,
   },
   props: {
      // 标题
      title: {
         type: String,
         required: true,
      },
      // 菜单
      menu: {
         type: Array,
         default: () => [],
      },
      // banner
      banner: {
         type: Array,
         default: () => [],
      },
   },
};
</script>

<style lang="scss" scoped>
.body {
   &-title {
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: var(--color-main);
   }

   &-box {
      display: flex;
      align-items: flex-start;
   }

   &-menu {
      margin-right: 50px;

      &__item {
         position: relative;
         margin-top: 15px;
         text-align: center;

         &:first-child {
            margin-top: 0;
         }
      }

      &__link {
         position: relative;
         display: block;
         font-size: 20px;
         font-weight: 600;
         color: var(--color-base);
         text-decoration: none;
         cursor: pointer;
         border-bottom: 1px solid currentColor;

         &:hover,
         &.active {
            color: var(--color-main);
         }

         &.router-link-active {
            cursor: default;
            color: var(--color-main);
            user-select: none;
         }

         &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            width: 20px;
            height: 1px;
            margin: 0 auto;
            background: #fff;
         }

         &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -3px;
            width: 4px;
            height: 4px;
            margin-left: -2px;
            border-radius: 50%;
            background: currentColor;
            border-top: none;
            border-bottom: none;
            box-sizing: content-box;
         }
      }

      &__sub {
         margin-top: 10px;

         &__item {
            padding: 5px 0;
            text-align: right;
         }

         &__link {
            display: inline-block;
            min-width: 3em;
            padding-bottom: 3px;
            font-size: 14px;
            color: var(--color-base);
            text-align: center;
            text-decoration: none;
            vertical-align: top;
            border-bottom: 1px solid currentColor;

            &:hover,
            &.router-link-exact-active {
               color: var(--color-main);
            }

            &.router-link-exact-active {
               cursor: default;
            }
         }
      }
   }

   &-content {
      flex: 1;
      overflow: hidden;
   }
}
</style>
