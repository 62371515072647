<template>
   <div class="">
      <el-row v-for="wenjuan in wenjuans" :key="wenjuan.id" :gutter="20">
         <el-col :span="2">
            <el-tag :type="status[wenjuan.status].type">
               {{ status[wenjuan.status].label }}
            </el-tag>
         </el-col>
         <el-col :span="18">
            <router-link
               :underline="false"
               :to="{ name: 'Wenjuan', params: { wenjuan_id: wenjuan.id } }"
            >
               {{ wenjuan.name }}
            </router-link>
         </el-col>
         <el-col :span="3">
            {{ wenjuan.time }}
         </el-col>
      </el-row>
   </div>
</template>

<script>
import { Tag, Row, Col, Dialog, Link } from "element-ui";
import { http } from "@/http";

export default {
   components: {
      [Tag.name]: Tag,
      [Row.name]: Row,
      [Col.name]: Col,
      [Dialog.name]: Dialog,
      [Link.name]: Link,
   },
   data() {
      return {
         wenjuans: [],
         status: {
            0: {
               label: "正在调查",
               type: "",
            },
            1: {
               label: "调查结束",
               type: "info",
            },
         },
      };
   },
   created() {
      this.getWenjuan();
   },
   methods: {
      getWenjuan() {
         http.get("wenjuan", {}, { loading: true }).then((res) => {
            this.wenjuans = res.data;
         });
      },
   },
};
</script>

<style scoped>
.el-row {
   height: 45px;
   line-height: 30px;
}
.el-row a {
   color: #000;
   text-decoration: none;
}
.el-row a:hover {
   color: #d1a861;
}
</style>
