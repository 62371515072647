<template>
   <div class="consult">
      <div class="consult-head">
         <strong>规则说明：</strong>
         欢迎对我们的产品提出宝贵建议，您有任何意见和建议，均可在此留言。请留下您的联系方式，我们会在三个工作日内答复，您也可以在工作时间拨打客户
         服务热线：
         <strong>4008855788</strong>
         进行咨询(限产品咨询) 或点击
         <strong>
            <a
               class="consult-head__link"
               href="#"
               @click.prevent="plateVisibile = true"
            >
               湖南中烟官方二维码验真平台
            </a>
         </strong>
         微信扫描二维码进行咨询，感谢您的支持与配合！
      </div>

      <form ref="form" class="consult-form" action="" @submit.prevent="submit">
         <div class="consult-form__item">
            <el-row :gutter="20">
               <el-col :span="8">
                  <input
                     v-model="formData.name"
                     class="consult-field"
                     type="text"
                     placeholder="姓名"
                     title="姓名"
                     maxlength="10"
                  />
               </el-col>
               <el-col :span="8">
                  <input
                     v-model="formData.email"
                     class="consult-field"
                     type="mail"
                     placeholder="邮箱"
                     title="姓名"
                  />
               </el-col>
               <el-col :span="8">
                  <input
                     v-model="formData.tel"
                     class="consult-field"
                     type="tel"
                     placeholder="电话"
                     title="电话"
                  />
               </el-col>
            </el-row>
         </div>

         <div class="consult-form__item">
            <textarea
               v-model="formData.des"
               class="consult-field"
               placeholder="内容(200字以内)"
               title="内容"
               maxlength="200"
               rows="4"
            ></textarea>
         </div>

         <div class="consult-form__item">
            <el-row :gutter="20">
               <el-col :span="4">
                  <input
                     v-model="formData.ver"
                     class="consult-field"
                     type="text"
                     placeholder="验证码"
                     title="验证码"
                  />
               </el-col>
               <el-col :span="6">
                  <img
                     class="consult-ver"
                     :src="verCodeStr"
                     title="获取验证码"
                     @click="verCode"
                  />
               </el-col>
            </el-row>
         </div>
         <div class="consult-form__item">
            <button class="consult-form__submit">提交</button>
         </div>
      </form>
      <el-table ref="expandstable" :data="consults" :expand-row-keys="expands">
         <el-table-column width="80">
            <template slot-scope="scope">
               <el-button type="primary" @click="expandsHandle(scope.row)">
                  <span v-if="!scope.row.isshow">查看回复</span>
                  <span v-if="scope.row.isshow">收起</span>
               </el-button>
            </template>
         </el-table-column>
         <el-table-column type="expand">
            <template slot-scope="props">
               <el-form label-position="left" inline>
                  <el-form-item label="回复时间：">
                     <span>{{ props.row.reply_time }}</span>
                  </el-form-item>
                  <el-form-item label="回复内容：">
                     <span>{{ props.row.reply_content }}</span>
                  </el-form-item>
               </el-form>
            </template>
         </el-table-column>
         <el-table-column
            class="el-table__header"
            label="姓名"
            prop="name"
         ></el-table-column>
         <el-table-column label="邮箱" prop="email"></el-table-column>
         <el-table-column label="咨询内容" prop="des"></el-table-column>
         <el-table-column label="咨询时间" prop="time"></el-table-column>
      </el-table>

      <el-dialog
         :visible.sync="plateVisibile"
         width="800px"
         class="consult-plate__dialog"
         title="湖南中烟官方二维码验真平台"
         append-to-body
      >
         <div class="consult-plate">
            <div class="consult-plate__col">
               <div class="consult-plate__item">
                  <img
                     src="@/assets/imgs/consult_qr_code_01.jpg"
                     alt=""
                     class="consult-plate__img"
                  />
                  <div class="consult-plate__title">
                     湖南中烟官方二维码验真服务小程序：橙杏服务
                  </div>
               </div>
            </div>
            <div class="consult-plate__col">
               <div class="consult-plate__item">
                  <img
                     src="@/assets/imgs/consult_qr_code_02.jpg"
                     alt=""
                     class="consult-plate__img"
                  />
                  <div class="consult-plate__title">
                     湖南中烟官方二维码验真服务公众号：橙杏服务
                  </div>
               </div>
            </div>
         </div>
      </el-dialog>
   </div>
</template>

<script>
import { http } from "@/http";
import {
   Row,
   Col,
   Message,
   Dialog,
   Table,
   TableColumn,
   FormItem,
   Form,
} from "element-ui";

import Schema from "async-validator";

// 校验规则
const validator = new Schema({
   name: [{ type: "string", required: true, message: "请输入姓名" }],
   email: [
      { type: "string", required: true, message: "请输入邮箱" },
      { type: "email", message: "请输入正确邮箱地址" },
   ],
   tel: [
      { type: "string", required: true, message: "请输入电话" },
      { type: "string", pattern: /1\d{10}/, message: "请输入正确手机号码" },
   ],
   des: [{ type: "string", required: true, message: "请输入内容" }],
   ver: [{ type: "string", required: true, message: "请输入验证码" }],
});

export default {
   name: "PageTobaccoConsult",
   components: {
      [Row.name]: Row,
      [Col.name]: Col,
      [Dialog.name]: Dialog,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
   },
   data() {
      return {
         expands: [],
         activeName: "1",
         consults: [],
         // 表单数据
         formData: {
            name: "",
            email: "",
            tel: "",
            des: "",
            ver: "",
         },
         // 验证码
         verCodeStr: "",
         plateVisibile: false,
      };
   },
   created() {
      this.getConsult();
      this.verCode();
   },
   methods: {
      getConsult() {
         http
            .get("consult", { loading: true })
            .then((res) => {
               this.consults = res.data;
            })
            .catch(() => {
               this.verCode();
            });
      },
      /**
       * 获取验证码
       */
      verCode() {
         // http.get("verCode", { loading: true }).then((res) => {
         //    this.verCodeStr = res.data;
         // });
         this.verCodeStr = `api/v1/consult/captcha?t=${new Date().getTime()}`;
      },
      expandsHandle(row) {
         row.isshow = !row.isshow;
         this.$refs.expandstable.toggleRowExpansion(row);
      },
      /**
       * 提交
       */
      submit() {
         let validatorError = "";

         validator.validate(this.formData, (error) => {
            if (error) {
               [{ message: validatorError }] = error;
            }
         });

         // 如果填写不完整
         if (validatorError) {
            Message({ type: "error", message: validatorError });
            return;
         }
         // http://localhost:8080/api/v1/consult/save
         // http://localhost:8080/api/v1/site/browseCount

         http
            .post("consult/save", this.formData, { loading: true })
            .then((res) => {
               const { msg: message } = res;

               Message({
                  message,
                  type: "success",
               });

               this.$refs.form.reset();
            })
            .catch(() => {
               this.verCode();
            });
      },
   },
};
</script>

<style lang="scss" scoped>
.el-table__header {
   background-color: #999;
}
.consult {
   &-plate {
      &__inner {
         position: relative;
         display: inline-block;
         padding: 5px 8px;
         font-size: 20px;
         color: #fff;
         font-weight: 700;
         line-height: 1;
         vertical-align: top;
         background: var(--color-main);

         &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 11px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-top-color: var(--color-main);
         }
      }
   }
   &-head {
      font-size: 16px;
      color: var(--color-fun);

      strong {
         font-weight: 700;
         color: var(--color-main);
      }

      &__link {
         color: inherit;
         text-decoration: none;

         &:hover {
            text-decoration: underline;
         }
      }
   }

   &-field {
      width: 100%;
      padding: 10px 0;
      resize: none;
      border: none;
      outline: none;
      border-bottom: 1px solid #eee;

      &:focus {
         border-color: var(--color-main);
      }
   }

   &-ver {
      min-width: 100px;
      min-height: 36px;
      height: 36px;
      cursor: pointer;
      background: #999;
   }

   &-form {
      &__item {
         margin-top: 20px;
      }

      &__submit {
         padding: 10px 20px;
         margin-top: 20px;
         font-size: 14px;
         cursor: pointer;
         color: var(--color-title);
         border: 1px solid #ddd;

         &:hover {
            color: #fff;
            border-color: transparent;
            background-color: var(--color-main);
         }
      }
   }

   &-plate {
      display: flex;
      margin: -10px;

      &__col {
         width: 50%;
         padding: 10px;
      }

      &__item {
         padding: 20px;
         border: 1px solid #eee;
         border-radius: 4px;
      }

      &__img {
         display: block;
         width: 80%;
         margin: 0 auto;
      }

      &__title {
         margin-top: 10px;
         font-size: 14px;
         text-align: center;
         color: var(--color-base);
      }

      &__dialog {
         ::v-deep {
            .el-dialog__header,
            .el-dialog__body {
               padding: 35px;
            }

            .el-dialog__header {
               padding-bottom: 15px;
            }

            .el-dialog__body {
               padding-top: 15px;
            }
         }
      }
   }
}
</style>
