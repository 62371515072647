<template>
   <div>
      <form class="search_wall" action="" @submit.prevent="submit">
         <i class="el-icon-search"></i>
         <input v-model="qiye_name" type="text" placeholder="请输入企业全称" />
         <button>查询</button>
      </form>
      <br />
      <h3>查询结果</h3>
      <el-table
         :data="tableData"
         style="text-align: center; width: 100%;"
         stripe
      >
         <el-table-column
            prop="name"
            style="background-color: #eee;"
            label="企业名称"
            width="260"
         ></el-table-column>
         <el-table-column
            prop="code"
            label="统一社会信用代码"
            width="240"
         ></el-table-column>
         <el-table-column
            prop="method"
            label="全资/控股/参股"
            width="180"
         ></el-table-column>
         <el-table-column
            prop="level"
            label="企业次级"
            width="180"
         ></el-table-column>
         <el-table-column
            prop="regedit_address"
            label="注册地"
            width="180"
         ></el-table-column>
      </el-table>
   </div>
</template>

<script>
import { http } from "@/http";
import { Table, TableColumn, FormItem, Form } from "element-ui";

export default {
   name: "PageQiye",
   components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
   },
   data() {
      return {
         qiye_name: "",
         tableData: [],
      };
   },
   created() {},
   methods: {
      submit(e) {
         const vm = this;
         if (vm.qiye_name === "") {
            return;
         }
         http
            .get(`site/qiye?name=${vm.qiye_name}`, { loading: true })
            .then((res) => {
               this.tableData = res.data;
            });
         console.log(e);
      },
   },
};
</script>

<style scoped>
h3 {
   margin: 1.5em 0 1em;
   font-weight: bold;
   font-size: 18px;
   color: var(--color-main);
}
.search_wall {
   float: right;
}
.search_wall i {
   padding-left: 10px;
   line-height: 42px;
   position: absolute;
   font-size: 18px;
}
.search_wall input {
   padding-left: 30px;
   margin-right: 25px;
   font-size: 18px;
   height: 42px;
   border: 1px solid #ccc;
}
.search_wall button {
   cursor: pointer;
   font-size: 18px;
   height: 42px;
   width: 80px;
   font-weight: 500;
   color: #fff;
   background-color: var(--color-main);
   border: 1px solid #ccc;
}
</style>
