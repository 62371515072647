<template>
   <div class="questionnaire">
      <h2>{{ wenjuan.name }}</h2>
      <el-form label-width="80px">
         <el-form-item v-for="(v, k) in wenjuan.ques" :key="k">
            <h4>{{ k + 1 }}，{{ v.title }}【{{ type_arr[v.type] }}】</h4>
            <el-radio-group v-if="v.type == 'radio'" v-model="checkList[v.id]">
               <el-radio
                  v-for="(x, y) in v.option"
                  :key="y"
                  class="user-radio"
                  :label="x.id"
               >
                  {{ x.title }}
               </el-radio>
            </el-radio-group>
            <el-checkbox-group
               v-if="v.type == 'checkbox'"
               :value="checkList[v.id]"
            >
               <el-checkbox
                  v-for="option in v.option"
                  :key="option.id"
                  :label="option.id"
                  class="user-radio"
                  @change="handleChange(v.id, option.id)"
               >
                  {{ option.title }}
               </el-checkbox>
            </el-checkbox-group>
         </el-form-item>
         <el-form-item v-if="wenjuan.status == 0">
            <el-row :gutter="20">
               <el-col :span="4">
                  <el-input
                     v-model="verCodeStr"
                     placeholder="请填写验证码"
                  ></el-input>
               </el-col>
               <el-col :span="6">
                  <img
                     class="consult-ver"
                     :src="verCodeImg"
                     title="获取验证码"
                     @click="verCode"
                  />
               </el-col>
            </el-row>
         </el-form-item>
         <el-form-item>
            <el-button
               v-if="wenjuan.status == 0"
               plain
               type="primary"
               icon="el-icon-circle-check"
               @click="onSubmit"
            >
               提交问卷
            </el-button>
            <el-button type="default" @click="goQuestionNaireResult">
               投票结果
               <i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
         </el-form-item>
      </el-form>
   </div>
</template>

<script>
import {
   Message,
   Button,
   RadioGroup,
   Radio,
   CheckboxGroup,
   Checkbox,
   Form,
   FormItem,
   Input,
   Col,
   Row,
} from "element-ui";
import { http } from "@/http";

export default {
   name: "PageTobaccoQuestionnaire",
   components: {
      [Button.name]: Button,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [CheckboxGroup.name]: CheckboxGroup,
      [Checkbox.name]: Checkbox,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Col.name]: Col,
      [Row.name]: Row,
   },
   data() {
      return {
         type_arr: {
            radio: "单选题",
            checkbox: "多选题",
         },
         checkList: [],
         wenjuan: {},
         verCodeStr: "",
         verCodeImg: "",
      };
   },
   created() {
      this.getQues();
      this.verCode();
   },
   methods: {
      handleChange(id, option) {
         const idx = this.checkList[id].indexOf(option);

         if (idx >= 0) {
            this.checkList[id].splice(idx, 1);
         } else {
            this.checkList[id].push(option);
         }
      },
      /**
       * 获取验证码
       */
      verCode() {
         // http.get("verCode", { loading: true }).then((res) => {
         //    this.verCodeStr = res.data;
         // });
         this.verCodeImg = `api/v1/consult/captcha?t=${new Date().getTime()}`;
      },
      goQuestionNaireResult() {
         this.$router.push({
            name: "WenjuanResult",
            params: { wenjuan_id: this.$route.params.wenjuan_id },
         });
      },
      getQues() {
         http
            .get(
               `wenjuan/${this.$route.params.wenjuan_id}`,
               {},
               { loading: true },
            )
            .then((res) => {
               res.data.ques.forEach((element) => {
                  this.$set(this.checkList, element.id, []);
               });
               this.wenjuan = res.data;
            });
      },
      onSubmit() {
         const cl = this.checkList;
         cl.forEach((element, key) => {
            if (element.length < 1) {
               cl.splice(key, 1);
            }
         });
         if (cl.length < this.wenjuan.ques.length) {
            Message({
               message: "请检查是否选择完整！",
               type: "error",
            });
            return;
         }

         if (this.verCodeStr.length < 5) {
            Message({
               message: "请填写完验证码",
               type: "error",
            });
            return;
         }
         http
            .post(
               "wenjuan",
               {
                  wenjuan_id: this.$route.params.wenjuan_id,
                  checkList: this.checkList,
                  ver: this.verCodeStr,
               },
               { loading: true },
            )
            .then((res) => {
               const { msg: message } = res;

               Message({
                  message,
                  type: "success",
               });

               this.$router.push({
                  name: "WenjuanResult",
                  params: { wenjuan_id: this.$route.params.wenjuan_id },
               });
            })
            .catch(() => {
               this.verCode();
            });
      },
   },
};
</script>

<style lang="scss" scoped>
.user-radio {
   .el-radio__input.is-checked + .el-radio__label {
      color: red;
   }
}

.questionnaire {
   h2 {
      text-align: center;
   }
   &-form {
      &__item {
         margin-top: 20px;
      }

      &__submit {
         padding: 10px 20px;
         margin-top: 20px;
         font-size: 14px;
         cursor: pointer;
         color: var(--color-title);
         border: 1px solid #ddd;

         &:hover {
            color: #fff;
            border-color: transparent;
            background-color: var(--color-main);
         }
      }
   }
}
</style>
