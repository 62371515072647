<template>
   <com-menu-body
      title="烟草服务"
      :menu="menu"
      :banner="[require('@/assets/imgs/banner_for_tobacco.jpg')]"
   ></com-menu-body>
</template>

<script>
import ComMenuBody from "@/components/MenuBody";

export default {
   name: "PageTobacco",
   components: {
      ComMenuBody,
   },
   data() {
      return {
         menu: [
            {
               label: "真假鉴别",
               path: "/tobacco/classroom",
               // children: [
               //    { label: "鉴真小课堂", path: "/tobacco/identify/classroom" },
               //    { label: "和天下", path: "/tobacco/identify/htx" },
               //    { label: "芙蓉王", path: "/tobacco/identify/frw" },
               //    { label: "白沙", path: "/tobacco/identify/bs" },
               // ],
            },
            { label: "科技创新", path: "/tobacco/innovate" },
            { label: "调查征集", path: "/tobacco/wenjuan" },
            { label: "咨询投诉", path: "/tobacco/consult" },
            { label: "全级次企业", path: "/tobacco/qiye" },
         ],
      };
   },
};
</script>

<style lang="scss" scoped></style>
